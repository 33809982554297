var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slip-all dont-copy" },
    [
      !_vm.detailsShow
        ? _c(
            "div",
            [
              _c("div", { staticClass: "calendar-top" }, [
                _c(
                  "svg",
                  {
                    staticClass: "iconpark-icon",
                    on: {
                      click: function ($event) {
                        return _vm.switchYear("reduce")
                      },
                    },
                  },
                  [_c("use", { attrs: { href: "#left" } })]
                ),
                _c(
                  "div",
                  { staticClass: "timePicker" },
                  [
                    _c("CbYearPicker", {
                      staticClass: "CbYearPicker",
                      attrs: { "allow-clear": false },
                      model: {
                        value: _vm.yearTime,
                        callback: function ($$v) {
                          _vm.yearTime = $$v
                        },
                        expression: "yearTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "svg",
                  {
                    staticClass: "iconpark-icon right",
                    on: {
                      click: function ($event) {
                        return _vm.switchYear("add")
                      },
                    },
                  },
                  [_c("use", { attrs: { href: "#left" } })]
                ),
              ]),
              _vm.isRequest && !_vm.payrollStatusList.length
                ? _c("CbNoData", { attrs: { width: "200" } })
                : _vm._l(_vm.payrollStatusList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "slip-all-item" },
                      [
                        _c(
                          "CbStatistics",
                          {
                            staticClass: "statistics",
                            attrs: {
                              "data-list": item.list,
                              length: "3",
                              "is-title": true,
                              title: item.period + "月工资条",
                              "title-width": 336,
                              "is-logo": true,
                              "is-function": true,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "right" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { prefix: "chakan-chakanmingxi" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetails(item.period)
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
            ],
            2
          )
        : _c("SlipTable", {
            attrs: { "current-time": _vm.currentTime },
            on: { goBack: _vm.goBack },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }