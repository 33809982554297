// 工资条
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 根据年份获取计薪年月的工资条各状态数量列表
export async function getStatusList(params) {
  return request(`${WAGE}/staffPayroll/getStatusList`, METHOD.GET, params)
}

// 根据年月获取计薪年月的工资条信息
export async function getStatusInfo(params) {
  return request(`${WAGE}/staffPayroll/getStatusInfo`, METHOD.GET, params)
}

// 获取工资条列表
export async function getStaffPayrollList(params) {
  return request(`${WAGE}/staffPayroll/getStaffPayrollList`, METHOD.GET, params)
}

// 单条发送工资条
export async function sendStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/sendStaffPayroll`, METHOD.POST, params)
}

// 立即发送工资条
export async function sendImmediatelyStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/sendImmediatelyStaffPayroll`, METHOD.POST, params)
}

// 定时发送工资条
export async function sendTimerStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/sendTimerStaffPayroll`, METHOD.POST, params)
}

// 确认工资条
export async function confirmStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/confirmStaffPayroll`, METHOD.POST, params)
}

// 查看工资条
export async function viewStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/viewStaffPayroll`, METHOD.POST, params)
}

// 单条撤回工资条
export async function withdrawStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/withdrawStaffPayroll`, METHOD.POST, params)
}

// 全部撤回工资条
export async function withdrawAllStaffPayroll(params) {
  return request(`${WAGE}/staffPayroll/withdrawAllStaffPayroll`, METHOD.POST, params)
}
